export default {
  props: {
    inputLabel: {
      type: Object,
      editor: 'InputLabel',
      default: () => ({
        position: null, // Не применять без явно заданной позиции
        width: 120,
        widthType: 'px'
      })
    }
  },
  computed: {
    /* CSS контейнера с надписью и полем ввода */
    elFormCssClasses () {
      let classes = ' is-no-asterisk '

      if (this.inputLabel) {
        // Позиционирование контейнеров
        classes += ` ${this.inputLabelPositionCssClasses}`
      }

      return classes
    },
    elFormCss () {
      let css = ''

      if (this.inputLabel) {
        // Позиционирование контейнеров
        css += ` ;${this.inputLabelPositionCss}; `
      }

      return css
    },

    /* CSS манипуляции над расположением контейнеров надписи и поля ввода */
    inputLabelPositionCssClasses () {
      let classes = ''

      if (this.inputLabel) {
        if (this.inputLabel.position) {
          // Не применять без явно заданной позиции
          classes += ' el-form-item-wrapper'

          // Стили расположений контейнеров src/components/InterfaceEditor/InterfaceViewer.css
          if (this.inputLabel.position === 'top') {
            classes += ' el-form-item__label-top'
          }

          if (this.inputLabel.position === 'left') {
            classes += ' el-form-item__label-left'
          }
        }
      }

      return classes
    },
    inputLabelPositionCss () {
      let css = ''

      if (this.inputLabel) {
        if (this.inputLabel.position) {
          const width = this.inputLabel.width
          const widthType = this.inputLabel.widthType
          css += ` --label-width: ${width}${widthType};`
        }
      }

      return css
    },

    /* CSS контейнера надписи */
    labelCssClasses () {
      let classes = 'registry_fields_label'

      if (this.inputLabel) {
        if (this.inputLabel.cssClasses) {
          classes += ` ${this.inputLabel.cssClasses}`
        }
      }

      if (this.requiredStyle === 'asterisk' && this.label) {
        classes += ' show_asterisk'
      }

      return classes
    },
    labelCss () {
      let css = ''

      if (this.inputLabel) {
        if (this.inputLabel.css) {
          css += ` ${this.inputLabel.css};`
        }
      }

      return css
    }
  }
}
